import React, { useRef, useState } from "react";
import festhomeOrange from "../../assets/images/festhome_orange.png";

const Filmmaker = () => {
  const videoRef = useRef(null); // Reference for the video element
  const [isPlaying, setIsPlaying] = useState(false); // State to track play/pause
  const [showButton, setShowButton] = useState(true);
  const togglePlayPause = () => {
    const video = videoRef.current;
    if (isPlaying) {
      video.pause();
    } else {
      video.play();
    }
    setIsPlaying(!isPlaying);
  };
  return (
    <>
      <section class="videoBanner">
        <div
          class="video-wrapper"
          onMouseEnter={() => setShowButton(true)}
          onMouseLeave={() => setShowButton(false)}
        >
          <div class="video__wrapper">
            {showButton && (
              <button
                type="button"
                class="video__play-buttons"
                data-button-state="pause"
                onClick={togglePlayPause}
              >
                {isPlaying ? (
                  <i class="bi bi-pause-fill"></i>
                ) : (
                  <i class="bi bi-play-fill"></i>
                )}
              </button>
            )}

            <video
              ref={videoRef}
              poster="https://imboxo.io/wp-content/uploads/2024/10/video-poster.png"
              onPlay={() => setIsPlaying(true)} // Updates the state when the video starts playing
              onPause={() => setIsPlaying(false)} // Updates the state when the video is paused
            >
              <source
                src="https://imboxo.io/wp-content/uploads/2024/09/video2.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </section>

      <section class="signinSec contact">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <iframe
                src="https://api.leadconnectorhq.com/widget/form/ixc4FQW6cm1En1QGAyJ0"
                id="inline-ixc4FQW6cm1En1QGAyJ0"
                data-layout="{'id':'INLINE'}"
                data-trigger-type="alwaysShow"
                data-trigger-value=""
                data-activation-type="alwaysActivated"
                data-activation-value=""
                data-deactivation-type="neverDeactivate"
                data-deactivation-value=""
                data-form-name="Frame Breakers Online Film Festival V2"
                data-height="1763"
                data-layout-iframe-id="inline-ixc4FQW6cm1En1QGAyJ0"
                data-form-id="ixc4FQW6cm1En1QGAyJ0"
                title="Frame Breakers Online Film Festival V2"
              ></iframe>
            </div>
          </div>
        </div>
        {/* <div class="container">
            <div class="row justify-content-center align-items-center">
                <div class="col-11 col-md-9">
                    <h2 class="mb-3 text-center">Project Details</h2>
                    <div class="formbox">
                        <div class="row">
                            <div class="col-12">
                                "<span class="text-danger">*</span>" indicates required fields
                            </div>
                            <div class="col-sm-6">
                                <label for="">Film Title <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" name="" id="" placeholder="" />
                            </div>
                            <div class="col-sm-6">
                                <label for="">Duration <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" name="" id="" placeholder="" />
                            </div>
                            <div class="col-sm-12">
                                <label for="">Brief Synopsis <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" name="" id="" placeholder="" />
                            </div>
                            <div class="col-12">
                                <h2 class="mb-3 text-center mt-3">Submitter Information
                                </h2>
                            </div>
                            <div class="col-sm-6">
                                <label for="">Producer / Director Name <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" name="" id="" placeholder="" />
                            </div>
                            <div class="col-sm-6">
                                <label for="">Date of birth <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" name="" id=""
                                    placeholder="" />
                            </div>
                            <div class="col-sm-6">
                                <label for="">Producer Email <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" name="" id="" placeholder="" />
                            </div>
                            <div class="col-sm-6">
                                <label for="">Contact No. <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" name="" id=""
                                    placeholder="" />
                            </div>
                            <div class="col-sm-6 col-md-3">
                                <label for="">City <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" name="" id=""
                                    placeholder="" />
                            </div>
                            <div class="col-sm- col-md-3">
                                <label for="">State <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" name="" id="" placeholder="" />
                            </div>
                            <div class="col-sm- col-md-3">
                                <label for="">Country <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" name="" id=""
                                    placeholder="" />
                            </div>
                            <div class="col-sm- col-md-3">
                                <label for="">Postal code <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" name="" id=""
                                    placeholder="" />
                            </div>
                            <div class="col-12">
                                <h2 class="mb-3 mt-5 text-center">Specifications
                                </h2>
                            </div>
                            <div class="col-sm-12 mb-4">
                                <h6>Project Type *</h6>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label" for="c1"><input class="form-check-input" type="checkbox" value="" id="c1">
                                        Animation
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label" for="c2"><input class="form-check-input" type="checkbox" value="" id="c2">
                                        Documentary
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label" for="c3"><input class="form-check-input" type="checkbox" value="" id="c3">
                                        Experimental Film
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label" for="c4"><input class="form-check-input" type="checkbox" value="" id="c4">
                                        Music Video
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label" for="c5"><input class="form-check-input" type="checkbox" value="" id="c5">

                                        Feature Film
                                    </label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <label class="form-check-label" for="c6"><input class="form-check-input" type="checkbox" value="" id="c6">
                                        Short Film
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <label>Genres <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" name="" id="" placeholder="Eg. Action, Drama" />
                            </div>
                            <div class="col-sm-6">
                                <label>Language <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" name="" id="" placeholder=" Eg. English" />
                            </div>
                            <div class="col-sm-6">
                                <label for="">Production Budget<span class="text-danger">*</span></label>
                                <input type="text" class="form-control" name="" id="" placeholder=" Eg. 5000 USD" />
                            </div>
                            <div class="col-sm-6">
                                <label for="">Completion Date <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" name="" id="" placeholder=" "/>
                            </div>
                            <div class="col-sm-6">
                                <label for="">Country of Filming <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" name="" id="" placeholder="" />
                            </div>
                            <div class="col-md-6">
                                <label for="">Submission Catagory <span class="text-danger">*</span></label>
                                <select name="" id="" class="form-select">
                                    <option value="">Best Short Film</option>
                                    <option value="">Best Animation Film</option>
                                    <option value="">Best Documentary Film</option>
                                    <option value="">Best Experimental Film</option>
                                    <option value="">Best  Feature Film</option>
                                    <option value="">Best Music Video</option>
                                </select>    
                            </div>
                            <div class="col-md-12">
                                <h2 class="mb-3 text-center mt-3 ">Add Project
                                </h2>
                                <label for="">Poster <span class="text-danger">*</span></label>
                                <div class="file-drop-area mt-2 mb-1">
                                    <span class="choose-file-button">Choose files</span>
                                    <span class="file-message">or drag and drop files here</span>
                                    <input class="file-input" type="file" multiple>
                                </div>
                                <small>Please upload your project to google drive, vimeo or you can also send us via we transfer. Please paste the link below.</small>
                            </div>
                            <div class="col-sm-6">
                                <label for="">Trailer Link <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" name="" id="" placeholder="" />
                            </div>
                            <div class="col-sm-6">
                                <label for="">Project Link <span class="text-danger">*</span></label>
                                <input type="text" class="form-control" name="" id="" placeholder="" />
                            </div>
                            <div class="form-check col-sm-12 pl-5">
                                <label class="form-check-label"><input class="form-check-input" type="checkbox" id="check1" name="option1" value="something">
                                    I agree to terms & conditions provided by the company. By providing my phone number, I agree to receive text messages from the business.</label>
                            </div>
                            <div class="col-12 mt-3">
                                <button class="btn btn-light" data-bs-toggle="modal" data-bs-target="#myModal">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
      </section>

      <script src="https://link.msgsndr.com/js/form_embed.js"></script>
    </>
  );
};

export default Filmmaker;
