import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import movieName from "../assets/images/1.webp";
import { tailer } from "../AppUtilities";
import { Link } from "react-router-dom";
import { IMG } from "../API/constant";

const HomeBannerSlide = ({ trandingMovie }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 4,
    },
    tabletlandscape: {
      breakpoint: { max: 767, min: 667 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 2,
    },
  };

  return (
    <>
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <Carousel
              responsive={responsive}
              swipeable={true}
              draggable={true}
              showDots={true}
              arrows={false}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={8000}
              customTransition="all 2s linear"
            >
              {trandingMovie === undefined
                ? ""
                : trandingMovie.map((item, index) => (
                    <div key={index}>
                      <div class="item text-center">
                        <div className="defaultPoster">
                          <Link to={`/movie-details/${item.slug}`}>
                            <img
                              src={IMG + item.poster}
                              alt=""
                              className="img-fluid"
                            />
                            {/* <p className="mt-3 text-light">{item.name}</p> */}
                          </Link>
                        </div>
                        <div className="onHoverPoster">
                          <video
                            src={tailer}
                            autoPlay
                            muted // autoplay usually requires muted to work in many browsers
                            loop // optional, to loop the video
                          ></video>
                          <div class="movie_content">
                            <span className="movieSName">
                              <img
                                src={movieName}
                                alt=""
                                className="img-fluid"
                              />
                            </span>
                            <div class="btngroup w-100">
                              <ul>
                                <li class="p-h-5">
                                  <Link to="/">
                                    <span class="btn banner_btn btn-light d-inline-block">
                                      <i class="fa-solid fa-play"></i>
                                    </span>
                                  </Link>
                                </li>

                                <li class="p-h-5">
                                  <span class="btn banner_btn btn-outline-light d-inline-block">
                                    <i class="bi bi-heart-fill"></i>
                                  </span>
                                </li>
                                <li class="p-h-5">
                                  <span class="btn banner_btn btn-outline-light d-inline-block">
                                    <i class="fa-solid fa-chevron-down"></i>
                                  </span>
                                </li>
                              </ul>
                              <ul class="d-flex btmText">
                                <li>2024</li>
                                <li>17min 56sec</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeBannerSlide;
