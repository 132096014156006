import axios from "axios";
import * as c from "../API/constant";
// ? REGISTRATION API
export const user_registration = async (data) => {
  try {
    const url = c.SIGNUP;
    const res = await axios.post(url, data);
    return res;
  } catch (e) {
    return e.response;
  }
};
export const otp_varification = async (data) => {
  try {
    const url = c.URL + "/email-verify";

    const res = await axios.post(url, data);
    return res;
  } catch (e) {
    return e.response;
  }
};
export const resend_otp = async (data) => {
  try {
    const url = c.URL + "/resend/otp";
    const res = await axios.post(url, data);
    return res;
  } catch (e) {
    return e.response;
  }
};
export const user_login = async (data) => {
  try {
    const url = c.LOGIN;
    const res = await axios.post(url, data);
    return res;
  } catch (e) {
    return e.response;
  }
};
export const user_forgotPass = async (data) => {
  try {
    const url = c.URL + "/reset-password-otp-send";
    const res = await axios.post(url, data);
    return res;
  } catch (e) {
    return e.response;
  }
};

export const forgotPass_new = async (data) => {
  try {
    const url = c.URL + "/reset-password";
    const res = await axios.post(url, data);
    return res;
  } catch (e) {
    return e.response;
  }
};
export const getuserDataID = async (header, data) => {
  try {
    const url = c.URL + "/user-details/" + data;
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });

    return res;
  } catch (e) {
    return e.response;
  }
};

export const getCreatorDataID = async (data) => {
  console.log("data", data);
  try {
    const url = c.URL + "/movie-list-with-creator-bio";
    console.log("url", url);
    const res = await axios.post(url, data);
    return res;
  } catch (e) {
    return e.response;
  }
};

export const creatorList = async () => {
  try {
    const url = c.URL + "/creator-list";
    const res = await axios.get(url);

    return res;
  } catch (e) {
    return e.response;
  }
};

export const creatorVideoAdd = async (data, header) => {
  try {
    const url = c.URL + "/movie-upload";
    const res = await axios.post(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const creatorVideoGetbyId = async (data, header) => {
  try {
    const url = c.URL + "/movie-details-user/" + data;
    console.log("url", url);
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const videoUpload = async (data, header) => {
  console.log("header", header);
  try {
    const url = c.URL + "/upload-video";
    const res = await axios.post(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};
export const getuser_update = async (data, header) => {
  console.log("data", data);
  try {
    const url = c.URL + "/user-update";
    const res = await axios.post(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const allCountry = async (header) => {
  try {
    const url = c.URL + "/country";
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};
export const allState = async (data, header) => {
  try {
    const url = c.URL + "/state/" + data;
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const allPlan = async (header) => {
  try {
    const url = c.URL + "/all-package";
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const paymentCreate = async (data, header) => {
  try {
    const url = c.URL + "/create-checkout-session";
    const res = await axios.post(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const paymentStatus = async (data, header) => {
  try {
    const url = c.URL + "/after-success-payment";
    const res = await axios.post(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const allCity = async (data, header) => {
  try {
    const url = c.URL + "/city/" + data;
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};
export const allFlimeCartfikt = async (header) => {
  try {
    const url = c.URL + "/get-certificate";
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const creatorSerise = async (data, header) => {
  try {
    const url = c.URL + "/create-series";
    const res = await axios.post(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const episodeAdd = async (data, header) => {
  try {
    const url = c.URL + "/add-episodes";
    const res = await axios.post(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const getEpisode = async (data, header) => {
  try {
    const url = c.URL + "/get-episodes/" + data;
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const getSeason = async (header) => {
  try {
    const url = c.URL + "/get-season";
    const res = await axios.get(url, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const getMovie = async (header) => {
  try {
    const url = c.URL + "/movie-list";
    const res = await axios.get(url);
    return res;
  } catch (e) {
    return e.response;
  }
};
export const getMoviebyFilter = async (data, header) => {
  try {
    const url = c.URL + "/movie-filter";
    const res = await axios.post(url, data);
    return res;
  } catch (e) {
    return e.response;
  }
};
export const trailerMovie = async (header) => {
  try {
    const url = c.URL + "/movie-trailer-list";
    const res = await axios.get(url);
    return res;
  } catch (e) {
    return e.response;
  }
};

export const getMovieAllProps = async (header) => {
  try {
    const url = c.URL + "/movie-props";
    const res = await axios.get(url);
    return res;
  } catch (e) {
    return e.response;
  }
};
export const getMovieCatagoryWais = async (header) => {
  try {
    const url = c.URL + "/movie-collection";
    const res = await axios.get(url);
    return res;
  } catch (e) {
    return e.response;
  }
};

export const getMovieDetils = async (data) => {
  try {
    const url = c.URL + "/movie-details/" + data;
    console.log("url", url);

    const res = await axios.get(url);
    return res;
  } catch (e) {
    return e.response;
  }
};
export const getMovieCastData = async (data) => {
  try {
    const url = c.URL + "/movie/cast/" + data;
    const res = await axios.get(url);
    return res;
  } catch (e) {
    return e.response;
  }
};

export const allAward = async (data) => {
  try {
    const url = c.URL + "/award-list/" + data;
    console.log("url", url);
    const res = await axios.get(url);
    return res;
  } catch (e) {
    return e.response;
  }
};

export const getMovieForCreator = async (data, header) => {
  try {
    const url = c.URL + "/movie-list-user";
    const res = await axios.post(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const commentDataAdd = async (data, header) => {
  try {
    const url = c.URL + "/movie-rating-comment";
    const res = await axios.post(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const getMovieDetailsS3 = async (data, header) => {
  try {
    const url = c.URL + "/fetch-file-url-from-S3";
    const res = await axios.post(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const addMovieCastCrew = async (data, header) => {
  try {
    const url = c.URL + "/add-movie-cast";
    const res = await axios.post(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};
export const addMovieCrew = async (data, header) => {
  try {
    const url = c.URL + "/add-movie-crew";
    const res = await axios.post(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const addAwards = async (data, header) => {
  try {
    const url = c.URL + "/add-award";
    const res = await axios.post(url, data, {
      headers: JSON.parse(header),
    });
    return res;
  } catch (e) {
    return e.response;
  }
};

export const getAllBlog = async () => {
  try {
    const url = c.URL + "/blog-list";
    console.log("url", url);
    const res = await axios.get(url);
    return res;
  } catch (e) {
    return e.response;
  }
};

export const getAllFaq = async () => {
  try {
    const url = c.URL + "/faq-list";
    console.log("url", url);
    const res = await axios.get(url);
    return res;
  } catch (e) {
    return e.response;
  }
};

export const getContact = async () => {
  try {
    const url = c.URL + "/contact-info";
    console.log("url", url);
    const res = await axios.get(url);
    return res;
  } catch (e) {
    return e.response;
  }
};

export const getMission = async () => {
  try {
    const url = c.URL + "/our-mission";
    console.log("url", url);
    const res = await axios.get(url);
    return res;
  } catch (e) {
    return e.response;
  }
};

export const getTeam = async () => {
  try {
    const url = c.URL + "/our-team";
    console.log("url", url);
    const res = await axios.get(url);
    return res;
  } catch (e) {
    return e.response;
  }
};

export const feedbackStore = async (data) => {
  try {
    const url = c.URL + "/feedback-store";
    const res = await axios.post(url, data);
    return res;
  } catch (e) {
    return e.response;
  }
};

export const getFeedback = async () => {
  try {
    const url = c.URL + "/feedback-list";
    const res = await axios.get(url);
    return res;
  } catch (e) {
    return e.response;
  }
};

export const getBlogDetails = async (data) => {
  try {
    const url = c.URL + "/blog-details/" + data;
    const res = await axios.get(url);
    return res;
  } catch (e) {
    return e.response;
  }
};

export const getPrivacy = async () => {
  try {
    const url = c.URL + "/privacy-policy";
    const res = await axios.get(url);
    return res;
  } catch (e) {
    return e.response;
  }
};

export const getTerms = async () => {
  try {
    const url = c.URL + "/terms-service";
    const res = await axios.get(url);
    return res;
  } catch (e) {
    return e.response;
  }
};

export const getGenre = async () => {
  try {
    const url = c.URL + "/genres";
    const res = await axios.get(url);
    return res;
  } catch (e) {
    return e.response;
  }
};

export const getIp = async () => {
  try {
    const url = "https://ipapi.co/json/";
    const res = await axios.get(url);
    return res;
  } catch (e) {
    return e.response;
  }
};

export const sentIp = async (data) => {
  try {
    const url = c.URL + "/ip-save";
    const res = await axios.post(url, data);
    return res;
  } catch (e) {
    return e.response;
  }
};
