import React from "react";
import Slider from "./Slider";
import ReleaseSlide from "./ReleaseSlide";

const LatestRelease = ({ allData, movieWishListAdd }) => {
  return (
    <>
      <section class="mt-5">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <h3 class="site_heading">
                Frame <span>Breakers</span>
              </h3>
            </div>
          </div>
        </div>

        <div class="latest_release noBg slide1 mt-1">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <ReleaseSlide
                  movieWishListAdd={movieWishListAdd}
                  allData={allData}
                />
              </div>
            </div>    
          </div>
        {/* <Slider allData={allData} /> */}
        </div>
      </section>
    </>
  );
};

export default LatestRelease;
