import React from "react";
import NgSliders from "./NgSliders";

const GeograpicSlider = ({ allCreator, allTrailer, movieWishListAdd }) => {
  return (
    <>
      <section class="top_week original mt-5">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-sm-12">
              {/* <NationalSlider allCreator={allCreator} /> */}

              <NgSliders
                movieWishListAdd={movieWishListAdd}
                allTrailer={allTrailer}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GeograpicSlider;
