import React from "react";
import MovieIcon from "../../assets/images/2.png";

const FailedPayment = () => {
  return (
    <>
      <section className="about innerpage">
        <div className="container">
          <div className="row justify-content-center align-items-center mt-5 pt-5">
            <div className="col-md-8 col-sm-10 text-left">
              <div className="failedLeft patterns film">
                <div className="row align-items-center justify-content-around">
                  <div className="col-sm-3">
                    <img
                      src={MovieIcon}
                      alt=""
                      className="img-fluid movieIcon"
                    />
                  </div>
                  <div className="col-md-6">
                    <div class="success-icon">!</div>
                    <div class="success-title">Payment Failed</div>

                    {/* <p>
                      Hey there. We tried to charge your card but, something
                      went wrong. Please update your payment method below to
                      continue
                    </p> */}
                    <span className="d-block ">
                      <a href="#" class="btn btn-danger mt-2">
                        Go To Home
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FailedPayment;
