import React from 'react';
import { careerPhoto } from '../../AppUtilities';

const Career = () => {
  return (
    <div>
      <section class="contentPage career innerpage">
        <div class="container-fluid">
            <div class="row justify-content-center align-items-center mb-5">
                <div class="col-md-7 col-lg-6">
                    <h3>Join Our Mission: Empower Creators, Shape the Future of Film
                    </h3>
                    <p>IMBOXO isn't just a platform; it's a global movement. Crafted by creators for creators, 
                    we are committed to empowering filmmakers and independent artists across every 
                    corner of the world. Our mission is to elevate diverse voices, foster authentic 
                    collaboration, and lead the charge in shaping the future of independent cinema. We're 
                    seeking passionate individuals who share this vision and want to make a meaningful 
                    impact. Whether you're an industry professional or a visionary with fresh ideas, we 
                    welcome you to join us in creating a world where creative expression transcends 
                    borders. Be part of something bigger—join a movement that's redefining the future of 
                    film, one story at a time.</p>
                    {/* <span><a href="#" class="btn btn-info">READ ABOUT OUR CULTURE <i class="fa-solid fa-arrow-right"></i></a></span> */}
                </div>
                <div class="col-md-5 col-lg-5 mb-4">
                    <img src={careerPhoto} alt="career" class="w-100" />
                </div>
            </div>

            <div class="row justify-content-center align-items-center flex-row-reverse">
                <div class="col-md-7 col-lg-6">
                    <h3>Be Part of a Culture That Champions Creativity and Inclusion
                    </h3>
                    <p>At IMBOXO, we thrive on collaboration, innovation, and inclusivity. We believe that 
                        when every voice is heard, creativity flourishes. Our team is driven by those who care 
                        deeply about filmmaking and storytelling and are committed to building a space where 
                        all perspectives are valued. We're always looking for passionate, driven creators who 
                        understand the power of stories and want to contribute to our unique culture. If you're 
                        passionate about film, technology, or the intersection of both and believe in the strength 
                        of community, we'd love to hear from you. Help us push the boundaries of what's 
                        possible—together, we'll shape the future of film in ways the world has yet to imagine</p>
                    {/* <span><a href="#" class="btn btn-info">See what's next <i class="fa-solid fa-arrow-right"></i></a></span> */}
                </div>
                <div class="col-md-5 col-lg-6 mb-4">
                    <img src={careerPhoto} alt="career" class="w-100" />
                </div>
            </div>
        </div>
    </section>

    <section class="p-v-100 parallax">
        <div class="container-fluid">
            <div class="row justify-content-center ">
                <div class="col-lg-6 col-md-8 col-sm-11">
                    <h2 class="text-center mb-5">Fill this form</h2>
                    <div className='borderBox'>
                        <div class="row align-items-center">
                            <div class="col-md-6 mb-4">
                                <input type="text" class="form-control" id="" placeholder="Name"/>
                            </div>
                            <div class="col-md-6 mb-4">
                                <input type="text" class="form-control" id="" placeholder="Email"/>
                            </div>
                            <div class="col-md-6 mb-4">
                                <input type="text" class="form-control" id="" placeholder="Take Inquiries "/>
                            </div>
                            <div class="col-md-6 mb-4">
                                <select name="" id="" class="form-select form-control">
                                    <option value="">All Positions</option>
                                    <option value="">creator</option>
                                    <option value="">production staff</option>
                                    <option value="">actor</option>
                                    <option value="">crew </option>
                                </select>
                            </div>
                            <div class="col-md-12 mb-4">
                                <label for="">Upload your file</label>
                                <div class="file-drop-area mt-2 mb-3">
                                    <span class="choose-file-button">Choose files</span>
                                    <span class="file-message">or drag and drop files here</span>
                                    <input class="file-input" type="file" multiple/>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <textarea name="" id="" placeholder="Industry Experience" class="form-control mb-3"></textarea>
                            </div>
                            <div class="form-check col-sm-6">
                                <label class="form-check-label"><input class="form-check-input" type="checkbox" id="check1" name="option1" value="something"/>
                                I agree and Submit.</label>
                            </div>
                            <div class="col-sm-6 text-end">
                                <button class="btn btn-info">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    </div>
  );
}

export default Career;
