import image from "../assets/images/noImage.png";
import bimage from "../assets/images/luckylady.jpg";
import unicon from "../assets/images/avter.png";
export const CURRENCY = "$";
export const TIMEZONE = "America/Los_Angeles";
export const NOIMG = image;
export const AVTER = unicon;
export const BNOIMG = bimage;
export const IMG = "https://ottadmin.imboxocinema.com/";

// ? ====== BASE URL ======

export const URL = "https://ottadmin.imboxocinema.com/api";
export const SURL = "https://ottadmin.imboxocinema.com";
//export const URL = "http://192.168.1.158:3000/api";

export const SIGNUP = `${URL}/signup`;
export const LOGIN = `${URL}/login`;
export const CEATOR = `${URL}/creator`;
