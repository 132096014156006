import React, { useEffect, useState } from 'react'
import * as API from "../../API/Index.js";
import { IMG } from '../../API/constant.js';
import { Link } from 'react-router-dom';
import moment from 'moment';
const BlogList = () => {
    const [allBlog, setAllBlog] = useState([])

    const getBlogDetails = async()=>{
        try {
            const response = await API.getAllBlog()
            console.log("response",response);
            setAllBlog(response.data.data)
        } catch (error) {
            
        }
    }


    useEffect(() => {
        getBlogDetails()
    }, [])
    
  return (
    <>
        <section class="movie_list">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-sm-11">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <h2 class="site_heading text-center mb-4">Blogs</h2>
                    </div>
                    {allBlog.map((item, index)=>(
                        <div class="col-12 col-sm-6 col-md-6 col-lg-4 mt-4">
                        <div class="blogbox">
                            <Link to={`/blog-details/${item.slug}`}>
                                <img src={IMG+ item.thumbnail} alt="blog-pic" class="img-fluid"/>
                                <div class="blogContent">
                                    <h4>{item.title}</h4>
                                    <small>{moment(item.created_at).format("DD MMMM, YYYY")}</small>
                                    <div dangerouslySetInnerHTML={{ __html: item.desc }} />
                                    <span class="btn btn btn-secondary2">Read More</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                    ))}
                    
                   
                </div>
            </div>
        </div>
    </div>
</section>
    
    </>
  )
}

export default BlogList