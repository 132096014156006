import React from 'react';
import { users } from '../../AppUtilities';

const Advertise = () => {
  return (
    <div>
        <section class="innerpage advertise">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 ">
                        <h2 class="site_heading mb-4">Reach Your Audience on Our OTT Platform
                        </h2>
                        <p>Welcome to our "Advertise with Us" page! As a leading over-the-top (OTT) streaming service, we provide a unique platform for brands to connect with engaged viewers. With a diverse content library and a growing user base, advertising with us means reaching your target audience where they spend their time.
</p>

                    </div>
                    <div class="col-md-6">
                        <h3>Why Advertise with Us?</h3>
                        <h5>Targeted Reach:</h5>
                        <p>Our platform offers advanced targeting options that allow you to reach specific demographics, interests, and viewing habits. This ensures your ads are seen by the right people at the right time.</p>
                        <h5>Engaging Content</h5>
                        <p>Our original programming and curated content create an immersive viewing experience. Advertisements are seamlessly integrated, enhancing brand visibility without interrupting the viewer’s enjoyment.</p>
                        <h5>Flexible Ad Formats</h5>
                        <p>Choose from a variety of ad formats, including pre-roll, mid-roll, post-roll, and display ads. Our customizable solutions cater to your marketing objectives and budget.</p>
                        <h5>Real-Time Analytics:</h5>
                        <p>Monitor your campaign’s performance with our robust analytics tools. Gain insights into viewer engagement, ad impressions, and click-through rates to optimize your strategy.</p>
                    </div>
                    <div class="col-md-6">
                        <h3>Our Audience</h3>
                        <p>Our viewers are diverse, spanning various demographics and interests. They seek quality content and are eager to engage with brands that resonate with their lifestyle. By advertising with us, you’ll tap into a community of loyal viewers who are actively seeking new products and services.</p>
                        <ul>
                            <li><strong>Contact Us:</strong> Reach out to our advertising team to discuss your goals, budget, and preferred ad formats. We’ll help you design a campaign that aligns with your objectives.</li>

                            <li><strong>Create Your Campaign:</strong> Once we understand your needs, we’ll work with you to create compelling ads that capture attention and drive action.</li>

                            <li><strong>Launch and Monitor:</strong> After your campaign goes live, you can monitor its performance through our analytics dashboard. We’ll provide regular updates to ensure your objectives are met.</li>
                        </ul>
                    </div>
                    <div class="col-12">
                        <h4>Partner with Us Today</h4>
                        <p>Join a growing list of brands that have successfully connected with our audience through innovative advertising strategies. Together, we can create meaningful interactions that elevate your brand.<br/><br/>

For more information or to get started, please contact us at gewu@mailinator.com or call 9874563210. We look forward to partnering with you!</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
  );
}

export default Advertise;
