import React, { useEffect, useState } from "react";
import HomeBanner from "../../components/BannerSlider";
import LatestRelease from "../../components/LatestRelease";
import Download from "../../components/Download";
import TopMovie from "../../components/TopMovies";
import SuperHeroes from "../../components/Superheroes";
import Geography from "../../components/Geography";
import GeograpicSlider from "../../components/GeograpicSlider";
import VideoSec from "../../components/VideoSliderSec";
import Brands from "../../components/Brands";
import Donate from "../../components/Donate";
import * as API from "../../API/Index.js";
import HomeBannerSlide from "../../components/HomeBannerSlide.js";
import { io } from "socket.io-client";
import MovieSlider from "../../components/TopMoviesSlider.js";
const Home = ({ allCreator }) => {
  const [allData, setAllData] = useState([]);
  const [allTrailer, setAllTrailer] = useState([]);
  const [catWaisMovieData, setCatWaisMovieData] = useState([]);
  const [trandingMovie, setTrandingMovie] = useState([]);
  const [topWeekMovie, setTopWeekMovie] = useState([]);
  const [bannerData, setBannerData] = useState([]);

  const allMovieData = async () => {
    const header = localStorage.getItem("_tokenCode");
    try {
      const response = await API.getMovie();
      console.log("response", response);
      const trresponse = await API.trailerMovie();
      const cataGoryres = await API.getMovieCatagoryWais();
      setCatWaisMovieData(cataGoryres.data.data.genre);
      setTopWeekMovie(cataGoryres.data.data.top_pick_week);
      setTrandingMovie(cataGoryres.data.data.latest_releases);
      console.log("cataGoryres", cataGoryres.data.data);
      setAllTrailer(trresponse.data.data);
      setAllData(response.data.data);
      // ?*OLNY FOR BANNER TRAILERDATA
      const reqObj = {
        type: "trailer",
        id: cataGoryres.data.data.banner_movie.id,
      };
      const bresponse = await API.getMovieDetailsS3(reqObj, header);
      console.log("getMovieDetailsS3", bresponse);
      setBannerData({
        movie: cataGoryres.data.data.banner_movie,
        trailer: bresponse.data.fileUrl,
      });
    } catch (error) {}
  };

  const movieWishListAdd = async (data) => {
    console.log("data", data);

    try {
    } catch (error) {}
  };

  // const socket = io("http://3.16.129.22:8081");

  // const driverdata = {
  //   driverId: "66cd6fe36b296c0ad7b49117",
  //   latitude: "22.574761",
  //   longitude: "88.433778",
  // };

  // socket.emit("updateLocation", driverdata);

  // // console.log("socket", socket);

  // socket.emit("fetchRideData", "66cd6fe36b296c0ad7b49117");

  // const datauser = {
  //   userId: "66d9ea6a789f342a7ad4b6d9",
  //   latitude: 22.5763574,
  //   longitude: 88.4334495,
  //   radiusInKm: 5,
  //   stops: [],
  // };
  // socket.emit("findNearbyDrivers", datauser);

  // socket.on("nearbyDrivers", (data) => {
  //   console.log("nearbyDrivers:", data);
  // });

  // socket.on("rideRequest", (data) => {
  //   console.log("rideRequest", data);
  //   // const rideInfo = data["66cd6fe36b296c0ad7b49117"];
  //   // console.log("rideInfo", rideInfo);
  // });

  // socket.on("acceptRide", (data) => {
  //   console.log("acceptRide", data);
  // });

  // socket.on("rideStatusComplete", (data) => {
  //   console.log("rideStatusComplete", data);
  // });

  useEffect(() => {
    allMovieData();
  }, []);
  return (
    <>
      <HomeBanner bannerData={bannerData} trandingMovie={trandingMovie} />

      {/* ? Frame Breakers */}
      <LatestRelease movieWishListAdd={movieWishListAdd} allData={allData} />

      {/* ?? All  */}
      <GeograpicSlider
        movieWishListAdd={movieWishListAdd}
        allTrailer={allTrailer}
      />

      {/* ? CATAGORI WAIS MOVIE */}
      {/* {Object.entries(catWaisMovieData).map(([genre, movies]) => (
        <TopMovie
          movieWishListAdd={movieWishListAdd}
          movies={movies}
          genre={genre}
        />
      ))} */}

      {/* ? Frame Breakers */}
      <section className="newreleasePart slide1 mt-5">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-sm-12 mb-1">
              <div class="bannerList m-0">
                <h3 class="site_heading">
                  Latest <span>Releases</span>
                </h3>
              </div>
            </div>
          </div>
        </div>

        <div className="latest_release noBg">
          <HomeBannerSlide
            // allData={allData}
            trandingMovie={trandingMovie}
          />
        </div>
      </section>

      {/* ?* TOP WEEK MOVIE */}
      <MovieSlider topWeekMovie={topWeekMovie} />

      {/* <Download /> */}
      {/* <SuperHeroes /> */}
      {/* <Geography />
     <GeograpicSlider allCreator={allCreator} /> */}
      {/* <VideoSec /> */}
      {/* <Brands /> */}
      {/* <Donate /> */}
    </>
  );
};

export default Home;
