import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as API from "../../API/Index.js";
const Plans = () => {
  const [plan, setPlan] = useState([]);
  const paymentPlan = async () => {
    const header = localStorage.getItem("_tokenCode");
    try {
      const response = await API.allPlan(header);
      console.log("response", response);
      setPlan(response.data.data);
    } catch (error) {}
  };

  const paymentStart = async (id, price) => {
    const header = localStorage.getItem("_tokenCode");
    try {
      const reqObj = {
        package_id: id,
        amount: price,
        user_id: localStorage.getItem("_userId"),
      };
      const response = await API.paymentCreate(reqObj, header);
      console.log("response", response);
      if (response.status === 200) {
        window.open(response.data.url, "_blank", "noopener,noreferrer");
      }
      //setPlan(response.data.data);
    } catch (error) {}
  };
  useEffect(() => {
    paymentPlan();
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section class="movie_list innerpage pricingPaid">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-md-11 text-center">
              <h2 class="text-capitalize">Choose your subscription plan</h2>
              <p>
                Explore advanced AI tools to enhance your marketing strategy.
              </p>
              <div class="d-flex justify-content-center align-items-center gap-3 mb-5">
                <span>Monthly</span>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="billingToggle"
                  />
                </div>
                <span>
                  Yearly
                  <small class="badge bg-warning text-light">
                    ( First 6 months free )
                  </small>
                </span>
              </div>

              <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
                {plan.map((item, index) => (
                  <div class="col-lg-3 col-md-6 col-sm-6" key={index}>
                    <div class="pricing-card">
                      <div class=" mb-0">
                        <h3 class="fw-bold">{item.name}</h3>
                        <p class="text-muted">{item.short_desc}</p>

                        <div class="price mb-4">
                          {item.name === "Enterprise" ? (
                            "Contact Sales"
                          ) : (
                            <>
                              {" "}
                              ${item.price}
                              <span class="period">/mo</span>
                            </>
                          )}{" "}
                        </div>
                        {item.name === "Enterprise" ? (
                          <Link to="/contact" class="get-plan-btn text-center">
                            Get Started
                          </Link>
                        ) : (
                          <button
                            onClick={() => paymentStart(item.id, item.price)}
                            class="get-plan-btn"
                          >
                            Get Started
                          </button>
                        )}

                        <p
                          class="text-muted"
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        ></p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Plans;
