import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import logoBig from "../../assets/images/about/logo-big.png";
import sponsor from "../../assets/images/about/sponsor.png";
import Client from "../../assets/images/about/client.png";
import SliderPic from "../../assets/images/about/1.jpg";
import Logo from "../../assets/images/about/logo.png";
import Mission from "../../assets/images/about/5.png";
import ColorLogo from "../../assets/images/about/6.png";
import DeskPic from "../../assets/images/about/7.png";
import Film from "../../assets/images/about/9.png";
import Movie from "../../assets/images/about/16.png";
import Globe from "../../assets/images/about/17.png";
import Smile from "../../assets/images/about/11.png";

const About = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 4,
    },
    tabletlandscape: {
      breakpoint: { max: 767, min: 667 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <section class="sponsor">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 text-center">
              <img src={logoBig} alt="logo" class="img-fluid mb-3" />
              <h3>
                <span class="color1">DREAM</span>
                <span class="color2">STREAM</span>
                <span class="color3">REPEAT</span>
              </h3>
            </div>
          </div>
        </div>
      </section>

      <section class=" sec2 patterns">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 text-center">
              <h2>
                Shaping The <span class="color1">Future</span> Of{" "}
                <span class="color3">Film</span> And Community
              </h2>
              <img src={sponsor} alt="" class="img-fluid mt-3 mb-3" />
            </div>
          </div>
        </div>
      </section>

      <section class="sec2 join patterns">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-11 mb-3">
              <div class="row justify-content-between align-items-center">
                <div class="col-md-8">
                  <h2>JOIN OUR JOURNEY</h2>
                  <h4>
                    {" "}
                    Where Creativity Meets Connection, and Every Story Finds Its
                    Stage
                  </h4>
                </div>
                <div class="col-md-3">
                  <img src={Client} alt="client" class="img-fluid" />
                </div>
              </div>

              <p>
                <i class="fa-solid fa-quote-left"></i>In a world where every
                story is a thread, we weave a tapestry of dreams, whispers, and
                wonders . Our platform is a stage where the unseen becomes
                unforgettable, and the ordinary transforms into the
                extraordinary . Here, a vibrant community blossoms, where
                independent filmmakers find their voice and fans embark on
                journeys of imagination . Our ecosystem nurtures connections,
                allowing creators and audiences to flourish together . We reward
                filmmakers fairly and transparently, celebrating their artistry
                with integrity . We celebrate the magic of individuality and the
                power of unity, inviting you to join us in a dance of creativity
                and connection . Embrace the uniqueness, share the passion, and
                be part of the ever - evolving story of IMBOXO{" "}
                <i class="fa-solid fa-quote-right"></i>
              </p>
              <h5 class="w-100">- Founder Roger Roth</h5>
            </div>
          </div>
        </div>
      </section>

      <section class="sec2 featured patterns">
        <div class="swiper mySwiper">
          <div class="purposeBOX">
            <h2>OUR PURPOSE</h2>
            <p>EMPOWERING FILM CREATORS Festivals & Community</p>
          </div>

          <Carousel
            responsive={responsive}
            swipeable={true}
            draggable={true}
            showDots={true} // Hide dots
            arrows={false} // Hide arrows
            //   ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
          >
            <div>
              <div class="item">
                <img src={SliderPic} alt="movie-pic" class="img-fluid" />
                <div class="featuredContent">
                  <h4>Movie Title Comes Here</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div class="item">
                <img src={SliderPic} alt="movie-pic" class="img-fluid" />
                <div class="featuredContent">
                  <h4>Movie Title Comes Here</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div class="item">
                <img src={SliderPic} alt="movie-pic" class="img-fluid" />
                <div class="featuredContent">
                  <h4>Movie Title Comes Here</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div class="item">
                <img src={SliderPic} alt="movie-pic" class="img-fluid" />
                <div class="featuredContent">
                  <h4>Movie Title Comes Here</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div class="item">
                <img src={SliderPic} alt="movie-pic" class="img-fluid" />
                <div class="featuredContent">
                  <h4>Movie Title Comes Here</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div class="item">
                <img src={SliderPic} alt="movie-pic" class="img-fluid" />
                <div class="featuredContent">
                  <h4>Movie Title Comes Here</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </p>
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </section>

      <section class=" mission">
        <div class="container-fluid">
          <div class="row align-items-center justify-content-center">
            <div class="col-md-5">
              <img src={Logo} alt="logo" class="img-fluid smallLogo" />
              <hr />
            </div>
            <div class="col-md-5 text-end">
              <h3 className="color3">Mindfully Driven, Future - Oriented</h3>
            </div>
            <div class="col-md-5">
              <h2 class="mb-3">MISSION.</h2>
              <p>
                Our mission is to unite and empower the indie film community by
                providing a platform where creativity thrives, stories are
                shared, and independent filmmakers can reach and inspire a
                global audience
              </p>
            </div>
            <div class="col-md-5">
              <img src={Mission} alt="logo" class="w-100" />
              <hr />
            </div>
            <div className="w-100"></div>
            <div class="col-md-2">
              <img src={ColorLogo} alt="logo" class="img-fluid" />
            </div>
            <div class="col-md-5">
              <p className="pr-5">
                {" "}
                <i class="fa-solid fa-quote-right"></i> IMBOXO was born from a
                passion for breaking barriers, creating new opportunities for
                filmmakers, and building a community where every story, no
                matter how small, can find its voice and resonate with the
                world.
              </p>
              <h5>- Roger Roth IMBOXO Founder</h5>
            </div>
            <div class="col-md-4">
              <h2 class="mb-3">VISION.</h2>
              <p>
                Our vision is to be the premier hub for independent cinema,
                fostering a vibrant and supportive ecosystem where diverse
                voices and unique perspectives are celebrated and connected,
                shaping the future of storytelling
              </p>
            </div>
          </div>
        </div>
      </section>

      <section class="p-v-60 stattegy">
        <div class="container-fluid">
          <div class="row align-items-center justify-content-center">
            <div class="col-md-4 text-center mb-3">
              <h2>
                CONTENT
                <br /> STRATEGY
              </h2>
              <h5>Guided by Innovation, Rooted in Storytellin</h5>
            </div>
            <div class="col-md-4 text-center">
              <img src={DeskPic} alt="" class="w-100" />
            </div>
            <div class="col-md-4">
              <p>
                Our content curation process is rigorous and intentional. We
                focus on films that push creative boundaries and resonate with
                our global audience. We prioritize a diverse mix of traditional,
                AI - driven, experimental, and genre - defying films, ensuring
                our library remains both innovative and reflective of the vast
                landscape of independent cinema
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="apps">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-4">
              <h2 class="heads text-dark">Mobile App</h2>
              <h4>Stay Tuned, Stay Together </h4>
              <p>
                Our mobile app is your gateway to the IMBOXO community, keeping
                you connected and engaged no matter where you are. With just a
                few taps, you can dive into live streams, join real-time
                conversations, and never miss a moment of what's happening on
                the platform. Whether you're watching a film, participating in a
                Q&A, or sharing your thoughts with fellow film enthusiasts, our
                app ensures you're always in the heart of the action. Because at
                IMBOXO, believe that storytelling is a shared experience, and
                with our app, you're never far from the story or the community
                that brings it to life.{" "}
              </p>
            </div>
            <div className="col-md-7">
              <div className="row">
                <div className="col-sm-5">
                  <img src="" alt="" />
                </div>
                <div className="col-sm-7">
                  <ul>
                    <li>
                      <span>
                        <img src="" alt="" />
                      </span>
                      <div>
                        <h4>INTERACTIVE FILM FESTIVALS</h4>
                        <h5>Shared Viewing Experiences</h5>
                        <p>
                          Participate in real-time voting and discussions during
                          our interactive film festivals.
                        </p>
                      </div>
                    </li>
                    <li>
                      <span>
                        <img src="" alt="" />
                      </span>
                      <div>
                        <h4>LIVE Q&A SESSIONS </h4>
                        <h5>Direct Interaction With Filmmakers </h5>
                        <p>
                          Engage directly with filmmakers through live Q&A
                          sessions after screenings.
                        </p>
                      </div>
                    </li>
                    <li>
                      <span>
                        <img src="" alt="" />
                      </span>
                      <div>
                        <h4>WATCH PARTIES</h4>
                        <h5>Shared Viewing Experiences</h5>
                        <p>
                          Watch movies together in real-time with friends and
                          fellow fans, no matter where they are.{" "}
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="relative patterns film">
        <div class="container-fluid p-0">
          <div class="row align-items-center">
            <div class="col-md-4 p-0">
              <img src={Film} alt="" className="w-100 extraheight" />
            </div>
            <div class="col-md-6 p-5">
              <h2 className="heads">FILMMAKERS </h2>
              <h4>Empowering Creators, One Story at a Time </h4>
              <ul>
                <li>
                  <span>
                    <img src={Movie} alt="" className="img-fluid" />
                  </span>
                  <div>
                    <h3>Empowering Every StoryS</h3>
                    <p>
                      IMBOXO gives independent filmmakers the tools to share
                      their unique stories with the world.
                    </p>
                  </div>
                </li>
                <li>
                  <span>
                    <img src={Globe} alt="" className="img-fluid" />
                  </span>
                  <div>
                    <h3>A Platform for All </h3>
                    <p>
                      Whether new or experienced, filmmakers can find a global
                      audience on IMBOXO.
                    </p>
                  </div>
                </li>
                <li>
                  <span>
                    <img src={Smile} alt="" className="img-fluid" />
                  </span>
                  <div>
                    <h3>Support and connectionS</h3>
                    <p>
                      Watch movies together in real-time with friends and fellow
                      fans, no matter where they are.{" "}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
