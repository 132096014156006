import React from "react";
import medSubmission from "../../assets/images/med_submission_btn@2x-mint-gradient.webp";
import festhomeOrange from "../../assets/images/festhome_orange.png";

const Festival = () => {
  return (
    <>
      <section class="contentPage career innerpage festival">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <h2 class="mb-4">Frame Breakers Online Film Festival</h2>
              <p>
                Welcome to the Frame Breakers Online Film Festival, where the
                thrill of cinema comes alive in the digital realm. This
                online-only event celebrates the art of storytelling, providing
                a platform for filmmakers from around the world to showcase
                their exceptional work to a global audience. Festhome is our
                official shipping method.
              </p>
              <span>
                <a
                  href="https://api.leadconnectorhq.com/widget/form/XeYEnOa6InzruZFlr3ra"
                  target="_blank"
                  class="btn btn-info"
                >
                  Submit Now
                </a>
              </span>
            </div>

            <div class="col-sm-6 col-md-6 row align-items-center">
              <span class="col-md-6 col-sm-6">
                <a
                  href="https://filmfreeway.com/festivals/80842?utm_campaign=Frame+Breakers+Online+Film+Festival&amp;utm_medium=External&amp;utm_source=Submission+Button"
                  target="_blank"
                  title="Click to submit on FilmFreeway"
                >
                  <img
                    src={medSubmission}
                    alt="med_submission_btn@2x-mint-gradient"
                    class="img-fluid"
                  />
                </a>
              </span>
              <span class="col-md-6 col-sm-6">
                <a href="https://festhome.com/f/8766">
                  <img
                    src={festhomeOrange}
                    alt="festhome_orange"
                    class="img-fluid"
                  />
                </a>
              </span>
              <div class="col-12 mt-4">
                <h3>Important Dates</h3>
                <ul>
                  <li>
                    1 November, 2024<span>Festival Start</span>
                  </li>
                  <li>
                    31 January, 2025<span>Festival End</span>
                  </li>
                  <li>
                    5 September, 2024<span>Submission Start</span>
                  </li>
                  <li>
                    20 October, 2024<span>Submissions Deadline</span>
                  </li>
                  <li>
                    25 October, 2024<span>Notification Date</span>
                  </li>
                </ul>
                <h5>Submission</h5>
                <small class="mb-3">
                  Participants can send their Films / Projects through email.
                  Mail Id -{" "}
                  <a href="mailto:roger@imboxo.com">roger@imboxo.com</a> &amp;{" "}
                  <a href="mailto:internetmovieboxoffice2@gmail.com">
                    internetmovieboxoffice2@gmail.com
                  </a>
                </small>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Festival;
