import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import * as API from "../../API/Index.js";
const SuccessPayment = () => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get("session_id");

  const paymentStart = async (id, price) => {
    const header = localStorage.getItem("_tokenCode");
    try {
      const reqObj = {
        session_id: sessionId,
        user_id: localStorage.getItem("_userId"),
      };
      console.log("reqObj", reqObj);

      const response = await API.paymentStatus(reqObj, header);
      console.log("response", response);

      //setPlan(response.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    //paymentPlan();
    if (sessionId) {
      paymentStart();
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="about innerpage">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-8 col-md-5">
              <div class="printer-top"></div>

              <div class="paper-container">
                <div class="printer-bottom"></div>

                <div class="paper">
                  <div class="main-contents">
                    <div class="success-icon">&#10004;</div>
                    <div class="success-title">Payment Complete</div>
                    {/* <div class="success-description">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text.
                    </div> */}
                    <div class="order-footer">Thank you!</div>
                    <center>
                      <span>
                        <Link to="/home" class="btn btn-secondary mt-5">
                          Go To Home
                        </Link>
                      </span>
                    </center>
                  </div>
                  <div class="jagged-edge"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SuccessPayment;
